import { graphql } from 'gatsby';
import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Page } from '../components/page';
import { RichText } from '../components/richt_text';
import { TGatsbyImageFluid } from '../typedefs/gatsby-image';

import './ueber-mich.scss';

export type TAboutMePageProps = {
  readonly data: {
    readonly image: TGatsbyImageFluid;
  };
};

type CVLine = {
  readonly timespan: string;
  readonly text: string;
};

const cvData: CVLine[] = [
  {
    timespan: '1965',
    text: 'geboren in Graz',
  },
  {
    timespan: '1983',
    text: 'Matura in Graz',
  },
  {
    timespan: '1987',
    text: 'Sponsion zum Magister der Rechtswissenschaft an der KF Uni Graz',
  },
  {
    timespan: '1989',
    text: 'Promotion zum Doktor der Rechtswissenschaft',
  },
  {
    timespan: 'seit 1993',
    text: 'Rechtsanwalt in Graz',
  },
  {
    timespan: 'seit 2007',
    text:
      'Vortragender an der FH Pinkafeld für (Bau-)Vertragsrecht, Baumanagement und Immobilienrecht',
  },
];

const AboutMePage: React.FunctionComponent<TAboutMePageProps> = (props: TAboutMePageProps) => {
  return (
    <Page title="Über mich">
      <RichText>
        <h1>Über mich</h1>

        <div className="employee">
          <span className="employee_name">Dr. Mario Sollhart</span>
          <div className="employee_wrapper">
            <div className="employee_image">
              <GatsbyImage
                image={props.data.image.childImageSharp.gatsbyImageData}
                alt={'Dr. Mario Sollhart'}
                imgStyle={{
                  objectFit: 'cover',
                  objectPosition: '100% 50%',
                }}
              />
            </div>
            <div className="cv">
              <div className="education">
                {cvData.map((line, index) => {
                  return (
                    <div className="cv_line" key={index}>
                      <span className="timespan">{line.timespan}</span>
                      <span className="cv_separator">-</span>
                      <span className="cv_text">{line.text}</span>
                    </div>
                  );
                })}
              </div>
              <b>Publikationen</b>
              <ul>
                <li>
                  <a
                    href="https://www.dbv.at/buecher/steuer-_und_wirtschaftsrecht/ratgeber_fuer_unternehmer/erfolgreich_als_selbststaendiger_4_auflage/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Erfolgreich als Selbstständiger
                  </a>
                  <br />
                  DBV Verlag, ISBN: 978-3-7041-0536-3
                </li>
                <li>
                  <a
                    href="https://www.dbv.at/verlagstitel_von_a_bis_z/der_freiberufler_als_unternehmer/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Der Freiberufler als Unternehmer
                  </a>
                  <br />
                  DBV Verlag, ISBN: 978-3-7041-0649-0
                </li>
              </ul>
            </div>
          </div>
        </div>
      </RichText>
    </Page>
  );
};

export default AboutMePage;

export const pageQuery = graphql`
  query AboutMePageQuery {
    image: file(relativePath: { eq: "mario-sollhart.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 670
          quality: 100
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
          layout: CONSTRAINED
        )
      }
    }
  }
`;
